var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full d-flex justify-space-between flex-column"},[_c('div',[_c('h2',{staticClass:"primary--text text-center mb-8"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".title")))+" ")]),_c('p',{class:{
        'text-text mb-5 px-md-10 primary--text text-center': true,
        'text-center': !_vm.kuspit_about_to_complete_view_model.small_screen,
      }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".description")))+" ")]),_c('p',{class:{
        'text-text mb-10 primary--text text-center': true,
        'text-center': !_vm.kuspit_about_to_complete_view_model.small_screen,
      }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".will_receive")))+" ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('div',_vm._l((_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".emails"))),function(email,index){return _c('div',{key:index,staticClass:"d-flex align-start"},[_c('img',{staticClass:"mr-5 mt-1",attrs:{"alt":"","src":require('@/assets/icons/email.svg'),"width":"24px"}}),_c('div',{staticClass:"w-full"},[_c('label',[_vm._v(" "+_vm._s(email.from)+" ")]),_c('p',{staticClass:"text-text dark-grey--text text-font-secondary-medium"},[_vm._v(" "+_vm._s(email.subject)+" ")])])])}),0)])]),_c('v-row',{attrs:{"align":"end"}},[_c('p',{class:{'text-text mb-5 primary--text px-md-10 pt-15 text-center': true,
        'text-center': !_vm.kuspit_about_to_complete_view_model.small_screen,}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".label")))+" ")]),_c('contract-savings-actions',{attrs:{"service-name":'kuspit',"is-loading":_vm.kuspit_about_to_complete_view_model.is_loading,"btn-disabled":_vm.kuspit_about_to_complete_view_model.is_loading},on:{"update:isLoading":function($event){return _vm.$set(_vm.kuspit_about_to_complete_view_model, "is_loading", $event)},"update:is-loading":function($event){return _vm.$set(_vm.kuspit_about_to_complete_view_model, "is_loading", $event)},"update:btnDisabled":function($event){return _vm.$set(_vm.kuspit_about_to_complete_view_model, "is_loading", $event)},"update:btn-disabled":function($event){return _vm.$set(_vm.kuspit_about_to_complete_view_model, "is_loading", $event)},"prevStep":function($event){return _vm.$emit('prevStep')},"nextStep":function($event){return _vm.kuspit_about_to_complete_view_model.createCustomerKuspitAccount()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }