

































































import { Vue, Component } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import KuspitAboutToCompleteViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-about-to-complete-view-model';

@Component({
  name: 'KuspitAboutToComplete',
  components: {
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class KuspitAboutToComplete extends Vue {
  kuspit_about_to_complete_view_model = Vue.observable(new KuspitAboutToCompleteViewModel(this));

  created() {
    this.kuspit_about_to_complete_view_model.initialize();
  }
}

